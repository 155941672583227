.skills__container{
    grid-template-columns: repeat(2,minmax(0,1fr));
    column-gap: 3rem;
    justify-content: center;
}

.skills__content{
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2rem 4rem;
    border-radius: 1.25rem;
}

.skills__title{
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    text-align: center;
    margin-bottom: var(--mb-1-5);
    color: var(--title-color-dark);
}

.skills__group{
    display: grid;
    align-items: flex-start;
    row-gap: 1rem;
}

.skills__data{
    display: flex;
    column-gap: 0.5rem;
}

.skills__badge-icon{
    font-size: 1.2rem;
    color: var(--title-color);
}

.skills__name{
    font-size: var(--normal-font-size);
    font-weight: var(--font-normal);
    line-height: 18px;
}

.skills__name b{
    color: var(--title-color-dark);
}

.skills__level{
    font-size: var(--smaller-font-size);
    line-height: 2.5rem;
}

/**==== Breakpoints ====**/
@media screen and (max-width: 768px){
    .skills__container{
        grid-template-columns: repeat(1,minmax(0,1fr));
    }

    .skills__content{
        padding: 2rem;
    }
}