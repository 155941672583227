/*======== GLOBAL STYLES ========*/

/** Google Fonts **/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

/**==== Gloabl Variables ====**/
:root{
    --header-height: 3rem;

    /** Colours **/
    --hue:0;
    --sat:0%;
    --title-color: hsl(var(--hue), var(--sat), 20%);
    --title-color-dark: hsl(var(--hue), var(--sat), 0%);
    --text-color: hsl(var(--hue), var(--sat), 46%);
    --body-color: hsl(var(--hue), var(--sat), 98%);
    --container-color: #fff;


    /** Fonts & typography **/
    --body-font: "Poppins", sans-serif;

    /** Fonts size **/
    --big-font-size: 3.5rem;
    --h1-font-size:2.25rem;
    --h2-font-size:1.5rem;
    --h3-font-size:1.25rem;
    --normal-font-size:1rem;
    --small-font-size:0.875rem;
    --smaller-font-size:0.813rem;
    --tiny-font-size:0.625rem;

    /** Fonts weight **/
    --font-normal:400;
    --font-medium:500;
    --font-semi-bold:600;

    /** Margin Bottom **/
    --mb-0-25:0.25rem;
    --mb-0-5:0.5rem;
    --mb-0-75:0.75rem;
    --mb-1:1rem;
    --mb-1-5:1.5rem;
    --mb-2:2rem;
    --mb-2-5:2.5rem;
    --mb-3:3rem;

    /** z index **/
    --z-tooltip: 10;
    --z-fixed: 100;
    --z-modal: 1000;
}

/**==== Gloabl Variables Mobile ====**/
@media screen and (max-width: 992px) {
    :root{

        /** Fonts size **/
        --big-font-size: 2.75rem;
        --h1-font-size:1.5rem;
        --h2-font-size:1.25rem;
        --h3-font-size:1rem;
        --normal-font-size:0.938rem;
        --small-font-size:0.813rem;
        --smaller-font-size:0.75rem;
    }
}

/**==== Base ====**/
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html{
    scroll-behavior: smooth;
}

body,
button,
input,
textarea{
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
}

body{
    background-color: var(--body-color);
    color: var(--text-color);
    overflow-x: hidden;
}

h1, h2, h3{
    color: var(--text-color);
    font-weight: var(--font-semi-bold);
}

ul{
    list-style: none;
}

a{
    text-decoration: none;
    color: var(--text-color);
}

button{
    cursor: pointer;
    border: none;
    outline: none;
}

img{
    max-width: 100%;
    height: auto;
}

/**==== Reusable classes ====**/
.section{
    padding: 4rem 0 2rem;
}

.section__title-large{
    font-size: var( --big-font-size);
    color: var(--title-color);
}

.section__title{
    font-size: var(--h1-font-size);
    color: var(--title-color);
}

.section_subtitle{
    display: block;
    font-size: var(--small-font-size);
    margin-bottom: 4rem;
}

.section__title,
.section_subtitle{
    text-align: center;
}

/**==== Layout ====**/
.container{
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
}

.grid{
    display: grid;
    gap: 1.5rem;
}

/**==== Buttons ====**/
.button{
    display: inline-block;
    background-color: var(--title-color);
    color: var(--container-color);
    padding: 0.8rem 1.2rem;
    border-radius: 1rem;
    font-weight: var(--font-medium);
}

.button:hover{
    background-color: var(--title-color-dark);
}

.button__icon{
    margin-left: var(--mb-0-5);
}

.button--flex{
    display: inline-flex;
    align-items: center;
}

/**==== Footer ====**/
.footer__container{
    background: #ebebeb;
    font-size: var(--small-font-size);
}
.footer__inner{
    grid-template-columns: repeat(2,minmax(0,1fr));
    padding: 3.5rem 1rem;
}
.developed{
    text-align: right;
}


/**==== Breakpoints ====**/
@media screen and (max-width: 992px){
    .container{
        margin-left: var(--mb-1-5);
        margin-right: var(--mb-1-5);
    }
    .button{
        padding: 1rem 1.75rem;
    }

}

@media screen and (max-width: 768px){
    .section{
        padding: 2rem 0 2rem;
    }
    .footer__container{
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 576px){

}

@media screen and (max-width: 350px){
    .container{
        margin-left: var(--mb-1);
        margin-right: var(--mb-1);
    }
}   
