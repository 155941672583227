.home__container{
    row-gap: 11rem;
}

.home__content{
    grid-template-columns: 116px repeat(2, 1fr);
    padding-top: 4rem;
    column-gap: 2rem;
    align-items: center;
}

.home__img{
    background: url(../../assets/profile-img.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: inset 0 0 0 9px rgb(255 255 255 / 30%);
    justify-self: center;
    order: 1;
    width: 300px;
    height: 300px;
    animation: profile_animate 8s ease-in-out infinite 1s;
}

@keyframes profile_animate{
    0%{
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }

    50%{
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
    }

    100%{
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
}

.home__social{
    display: grid;
    grid-template-columns: max-content;
    row-gap: 0.5rem;
}

.home__social-icon{
    font-size: 1.35rem;
    color: var(--title-color);
}

.home__social-icon:hover{
    color: var(--title-color-dark);
}

.home__title{
    font-size: var(--h1-font-size);
    margin-bottom: var(--mb-0-25);
    color: var(--title-color-dark);
}

.home__subtitle{
    position: relative;
    font-size: var(--h3-font-size);
    font-weight: var(--font-normal);
    margin-bottom: var(--mb-1);
    color: var(--title-color-dark);
}

.home__description{
    max-width: 400px;
    margin-bottom: var(--mb-3);
}

.uil-message{
    margin-left: 0.5rem;
}

/**==== Breakpoints ====**/
@media screen and (max-width: 992px){
    .home__content{
        grid-template-columns: 100px repeat(2, 1fr);
        column-gap: 1.25rem;
    }

    .home__description{
        max-width: initial;
        margin-bottom: var(--mb-2-5);
    }
    
    .home__img{
        box-shadow: inset 0 0 0 8px rgb(255 255 255 / 30%);
        width: 270px;
        height: 270px;
    }
}

@media screen and (max-width: 768px){
    .home__content{
        grid-template-columns: 0.5fr 3fr;
        padding-top: 0rem;
    }

    .home__img{
        order: initial;
        justify-self: initial;
        margin-left: 5rem;
    }

    .home__data{
        grid-column: 1/3;
        text-align: center;
    }
}

@media screen and (max-width: 576px){
    .home__img{
        margin-left: 0;
    }
}

@media screen and (max-width: 350px){

}  